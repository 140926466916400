import React from 'react';

import SEO from '../components/layout/SEO';

// Used as a redirect for an existing url sent out to clients to link to good cause PDF
const ThirdSector = () => (
    <SEO
        allowIndexing={false}
        redirect="https://drive.google.com/file/d/1A_m6FI_mv0CUbQY-sIfPa2djl1J4FPh7/view?usp=sharing"
    />
);

export default ThirdSector;
